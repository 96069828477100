import React, { FC, ReactNode } from 'react'

import { formatClassList, joinStrings } from '@bscs-dev-team/bscs-design-system-common'

import { Button } from '@bscs-dev-team/bscs-design-system-core'


type SnipcartButtonProps = {
  children: ReactNode,
  className?: string,
  custom1?: string,
  description: string,
  disabled?: boolean,
  id: string,
  max_quantity?: number,
  metadata?: unknown,
  min_quantity?: number,
  name: string,
  onClick?: () => void,
  price: number,
  quantity: number,
  shippable?: boolean,
  url: string
}

const BUTTON: string = `
  snipcart-add-item
`

const SnipcartButton: FC<SnipcartButtonProps> = ({
  children,
  className,
  custom1,
  description,
  disabled,
  id,
  max_quantity=1,
  metadata,
  min_quantity=1,
  name,
  onClick,
  price,
  quantity,
  shippable=false,
  url
}: SnipcartButtonProps) => {
  const ENV: string | undefined = process.env.GATSBY_ENVIRONMENT

  if (!ENV) {
    return <React.Fragment />
  }

  const formattedButton = formatClassList(
    className
      ? joinStrings(' ', className, BUTTON)
      : BUTTON
  )

  const fullUrl = ENV === 'production'
    ? `https://bscs.org/${url}`
    : ENV === 'stage'
      ? `https://stage.bscs.org/${url}`
      : ENV === 'tailwind'
        ? `https://tailwind.bscs.org/${url}`
        : `http://127.0.0.1:8000/${url}`

  if (!custom1) {
    return (
      <Button
        className={formattedButton}
        data-item-description={ description }
        data-item-id={ id }
        data-item-max-quantity={ max_quantity || 1 }
        data-item-metadata={ JSON.stringify(metadata) }
        data-item-min-quantity={ min_quantity || 1 }
        data-item-name={ name }
        data-item-price={ price }
        data-item-quantity={ quantity || 1 }
        data-item-shippable={ shippable }
        data-item-url={ fullUrl }
        disabled={ disabled }
        onClick={ onClick }
        title='Checkout'
      >
        { children }
      </Button>
    )
  }

  return (
    <Button
      className={formattedButton}
      data-item-custom1-name={ custom1 }
      data-item-custom1-required="true"
      data-item-description={ description }
      data-item-id={ id }
      data-item-max-quantity={ max_quantity }
      data-item-metadata={ JSON.stringify(metadata) }
      data-item-min-quantity={ min_quantity }
      data-item-name={ name }
      data-item-price={ price }
      data-item-quantity={ quantity }
      data-item-shippable={ shippable }
      data-item-url={ fullUrl }
      disabled={ disabled }
      onClick={ onClick }
      title='Checkout'
    >
      { children }
    </Button>
  )
}

export default SnipcartButton
