import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {
  Breadcrumb,
  BreadcrumbItem,
  PageTitle,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import AMedicalMysteryPdForm from '../../components/a-medical-mystery-pd-form'
import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'


const ProfessionalDevelopmentToSupportAMedicalMysteryRegistrationPage = () => {
  const resource = {
    title: "Professional Development to Support A Medical Mystery",
    snipcart: {
      options: [
        {
          buttonText: "Pay Now",
          description: "Registration payment for Professional Development to Support A Medical Mystery Part 1 Only",
          id: "professional-development-to-support-a-medical-mystery-part-1-only",
          max_quantity: 1,
          metadata: {
            sendto: [
              "Sue Kowalski",
              "Lisa Carey"
            ]
          },
          min_quantity: 1,
          name: "Registration payment for Professional Development to Support A Medical Mystery Part 1 Only",
          option: "part 1 only",
          price: 110,
          quantity: 1,
          shippable: false,
        },
        {
          buttonText: "Pay Now",
          description: "Registration payment for Professional Development to Support A Medical Mystery Part 1 and Part 2",
          id: "professional-development-to-support-a-medical-mystery-part-1-and-part-2",
          max_quantity: 1,
          metadata: {
            sendto: [
              "Sue Kowalski",
              "Lisa Carey"
            ]
          },
          min_quantity: 1,
          name: "Registration payment for Professional Development to Support A Medical Mystery Part 1 and Part 2",
          option: "part 1 and part 2",
          price: 350,
          quantity: 1,
          shippable: false,
        },
      ]
    }
  }

  return (
    <Layout>
      <SEO
        title="Professional Development to Support a Medical Mystery Registration"
        description=""
        canonicalUrl="https://bscs.org//professional-development-to-support-a-medical-mystery-registration/"
      />
      <Breadcrumb>
        <BreadcrumbItem>
          <Link className='inline-anchor' to='/'>Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          Professional Development to Support a Medical Mystery Registration
        </BreadcrumbItem>
      </Breadcrumb>
      <PageTitle>Professional Development to Support a Medical Mystery Registration</PageTitle>
      <TitleBorder />
      <div className="mb-5 justify-center">
        <h2>Secure your seat</h2>
        <VerticalCard>
          <VerticalCardBody>
            <div className="flex flex-wrap justify-center">
              <AMedicalMysteryPdForm
                className="w-full"
                resource={resource}
              />
            </div>
          </VerticalCardBody>
        </VerticalCard>
      </div>
    </Layout>
  )
}

export default ProfessionalDevelopmentToSupportAMedicalMysteryRegistrationPage

