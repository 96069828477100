import React, { FC, useState } from 'react'

import SnipcartButton from '../snipcart-button'

import { FormRadioField } from '@bscs-dev-team/bscs-design-system-core'


type Option = {
  description: string,
  disabled: boolean,
  id: string,
  max_quantity?: number,
  metadata: string,
  min_quantity?: number,
  name: string
  price: number,
  quantity: number,
  shippable: boolean,
  buttonText: string
}

type Snipcart = {
  options: Option[]
}

type Resource = {
  snipcart: Snipcart
}

type AMedicalMysteryPdFormProps = {
  onClick: () => void,
  resource: Resource
}

const AMedicalMysteryPdForm: FC<AMedicalMysteryPdFormProps> = ({
  onClick,
  resource
}: AMedicalMysteryPdFormProps) => {
  const [option, setOption] = useState<string>('')

  return (
    <React.Fragment>
      <FormRadioField
        title="I'd like to enroll in"
        labels={resource.snipcart.options.map((option) => option.option)}
        setValue={(value: string) => setOption(value)}
      />
      <p className="px-3" style={{ fontSize: '1.1rem' }}>
        <sup>*</sup>NSF funding has made participation discounts possible (original costs were $350 for part 1 and $590 for parts 1 and 2).
      </p>
      <div className="flex justify-end">
        {option === resource.snipcart.options[0].id
          ?
            <SnipcartButton
              className={`snipcart-add-item btn btn-outline-primary btn-sm`}
              description={ resource.snipcart.options[0].description }
              disabled={ resource.snipcart.options[0].disabled }
              id={ resource.snipcart.options[0].id }
              max-quantity={ resource.snipcart.options[0].max_quantity || 1 }
              metadata={ resource.snipcart.options[0].metadata }
              min-quantity={ resource.snipcart.options[0].min_quantity || 1 }
              name={ resource.snipcart.options[0].name }
              onClick={onClick}
              price={ resource.snipcart.options[0].price }
              quantity={ resource.snipcart.options[0].quantity || 1 }
              shippable={ resource.snipcart.options[0].shippable }
              url="products/registrations.json"
            >
              {resource.snipcart.options[0].buttonText}
            </SnipcartButton>
          :
            <SnipcartButton
              className={`snipcart-add-item btn btn-outline-primary btn-sm`}
              description={ resource.snipcart.options[1].description }
              disabled={ resource.snipcart.options[1].disabled }
              id={ resource.snipcart.options[1].id }
              max-quantity={ resource.snipcart.options[1].max_quantity || 1 }
              metadata={ resource.snipcart.options[1].metadata }
              min-quantity={ resource.snipcart.options[1].min_quantity || 1 }
              name={ resource.snipcart.options[1].name }
              onClick={onClick}
              price={ resource.snipcart.options[1].price }
              quantity={ resource.snipcart.options[1].quantity || 1 }
              shippable={ resource.snipcart.options[1].shippable }
              url="products/registrations.json"
            >
              {resource.snipcart.options[1].buttonText}
            </SnipcartButton>
        }
      </div>
    </React.Fragment>
  )
}

export default AMedicalMysteryPdForm
